@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');

.navbar {
    background-color: #fff!important;
    padding: .25rem 1rem;
    z-index: 9999 !important;
    
}

.navbar-brand img {
    width: 350px;
}

.navbar-light .navbar-nav .nav-link {
    font-family: PT Sans, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #2F353EF5;
    padding-right: 1rem;
    z-index: 9999 !important;
    
}
.navbar .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: #98DAF0;
    z-index: 9999 !important;
}

.navbar .navbar-toggler:focus, .navbar .navbar-toggler-icon:focus {
    outline: transparent;
}

.dropdown-menu {
    padding: 0;
    border: none;
}

.dropdown-item a, .navLink {
    color:#2F353EF5;;
    z-index: 9999 !important;
    transition: .15s;
}

.dropdown-item a:hover, .navLink:hover {
    color:#00AAE4;
    background-color: transparent;
    text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color:transparent!important;
}

.navbar-collapse.collapse.show

/* a.navLink.dropdown-item.active {
    background-color: #007bff !important
} */

a.navLink.dropdown-item.active {
    color:#2F353EF5;
    background-color: transparent !important;
}

@media only screen and (max-width: 991.97px) {
    .navbar-brand img {
        width: 250px;
    }

    .navbar-nav {
        width: 100%
    }

    .navbar-nav .nav-link {
        padding-left: 1rem;
    }

    .navbar .dropdown-toggle::after {
        float: right;
    }

    .navbar-nav .clrd-btn {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-brand {
        width: 80%;
    }
    .navbar-brand img {
        max-width:100%;
    }
}

@media only screen and (max-width: 476.75px) {
    /* .navbar-brand img {
        width: 275px;
    } */
  }

  @media only screen and (max-width:399.91px) {
    .navbar-brand {
        width: 70%;
    }

    .navbar-brand img {
        width: 190px;
    }
    .basic-navbar-nav {
      height: 40px!important;
    }
  }

  /* @media(hover: hover) and (pointer: fine) {
    .dropdown:hover .dropdown-menu {
        display: block;
    }
} */

/* #ModalView{
    padding-top: 8vw;
}
.modal-header .modal-title{
    color: #0481b5;
    margin-left: 9vw;
}
.borrowerLeadGeneration .blg-col-0 {
    display: none;
  }
.btn-primary {
    background: #00aae4 0 0 no-repeat padding-box;
    border-radius: 4px;
    border-color: transparent;
    line-height: 20px;
    font-size: 16px;
    font-family: soleil,sans-serif;
    font-weight: 600;
    font-style: normal;
    padding: 0.75rem 1rem;
}
.btn-primary:hover{
    background: #00aae4;
} */
